import * as React from "react"
import { useState } from 'react'
import { Link } from "gatsby"
import styled from "styled-components"
import oldWeb from "../../../images/pp/old.png"
import newWeb from "../../../images/pp/new.png"
import Projects from "../../../components/pp_projects"
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import Brand from "../../../components/pp_brand"
import Layout from "../../../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Footer from '../../../components/footer'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Wrapper from '../../../components/wrap';
import Dstitle from "../../../images/pp/head/web/title.inline.svg"
import Powered from "../../../images/pp/head/web/powered.inline.svg"
import Space from "../../../images/pp/head/web/space.inline.svg"
import Spaces from "../../../images/pp/head/web/spaces.inline.svg"
import Vehicle from "../../../images/pp/head/web/car.inline.svg"
// markup
const IndexPage = () => {
  const [isEnterMouse, setIsEnterMouse] = useState(false);

  const handleBackground = state => {
    setIsEnterMouse(state);
    if (state) {
      setIsEnterMouse(Math.floor(Math.random() * 5));
    }
  };

  return (
    <Layout navtype="dark" title="Premium Parking">
      <Head
        className={isEnterMouse ? 'hd-' + isEnterMouse : ''}
        onMouseEnter={() => handleBackground(true)}
        onMouseMove={() => handleBackground(true)}
        onMouseLeave={() => handleBackground(false)}>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={0}>
          <Spaces className="space space-first" />
          <div className="spot">
            <Space className="space" />
            <AnimationOnScroll className="vehicle" animateIn="animate__slideInRight" animateOnce="true" offset={0}>
              <Vehicle />
            </AnimationOnScroll>
          </div>
          <Powered className="badge" />
        </AnimationOnScroll>
      </Head>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <h1>
          <small>Premium Parking</small>
          A full rebrand of Premium Parking, including the new design system</h1>

        <ul className="types">
          <li>
            <small>Timeframe</small>
            <br />
            Jul 2019-Mar 2020
          </li>
          <li>
            <small>Role</small>
            <br />
            Lead Product Designer
          </li>
        </ul>
        <br/>
        <p>
          <a href="https://premiumparking.com" target="_blank" rel="noreferrer">Premium Parking</a> is digital parking solution in the US, with over 500 employees and $105.7M in annual revenue.
        </p>
        <p>During my work on <Link to="/projects/premium-parking/mobile">mobile apps</Link>, I proposed designing a new brand identity. Premium Parking has decided to invest in the brand to strengthen its premium position. The new look will bring consistency to existing and new products (amount grew) and a distinctive personality to Premium's assets.
        <br/>
        <AnchorLink to="#results">View Results</AnchorLink>
        </p>
      <h3>Historical context</h3>
      <OldBrand>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/old_web.png"
          alt="Map"
        />
      </OldBrand>
      <strong className="sub-title">Challenges and goals</strong>
      <h3>De-risking of the changes</h3>
      <p>
        Our goal was to coordinate the launch across web, platform and mobile apps and offline signs, so that a customer who parked vehicle recognize the brand at all steps. Especially when the Premium collaborated with other brands.
        <br/>
        On the web app, we wanted to use the same checkout workflow as on mobile apps, which I designed during <Link to="/projects/premium-parking/mobile">redesigning mobile apps</Link>. Also, the marketing team asked for a tool for building custom landing pages. For the other products, we wanted to maintain the existing functionality, but as much as possible. We were focused on updating the aesthetics instead of totally rethinking the way things worked. We also wanted to guarantee that the new design system met AA accessibility standards from the start.
      </p>
      <strong className="sub-title">My role and the team</strong>
      <h3>Smooth collaboration</h3>
      <p>
        For these big changes, Premium Parking hired a US-based <a href="https://southleft.com/" target="_blank" rel="noreferrer">SouthLeft agency</a>. SouthLeft worked on logo, color scheme, and offline materials. Also, it was my collaborative work with Southleft on the Design System and Web-app.
      </p>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
        <Brand />
      </AnimationOnScroll>
      </Wrapper>
      <Ds>
        <Dstitle />
        <div className="d-row">
        <div className="col fc">
          <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/web/c1_r1.png"
              alt="Map"
            />
          </AnimationOnScroll>
          <br/>
          <AnimationOnScroll className="shift-r" animateIn="animate__slideInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/web/c1_r2.png"
              alt="Checkout"
            />
          </AnimationOnScroll>
        </div>
        <div className="col cc">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/web/c2_r1.png"
              alt="Map"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/web/c2_r2.png"
              alt="Checkout"
            />
          </AnimationOnScroll>
        </div>
        <div className="col sc">
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/web/c3.png"
              alt="Checkout"
            />
          </AnimationOnScroll>
        </div>
        <div className="col rc">
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/web/c4_r1.png"
              alt="Map"
            />
          </AnimationOnScroll>
          <br/>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/web/c4_r2.png"
              alt="Checkout"
            />
          </AnimationOnScroll>
        </div>
        </div>
      </Ds>
      <Wrapper>
      <strong className="sub-title">De-risking the new flow</strong>
      <h3>Applying the rebrand and redesign the website</h3>
      <p>
        We used all our learnings from other products to improve the rates and location pages. This included making sure they matched our users' mental models and could grow like the mobile apps. To keep the conversion rate on the same level, we decided to test updates on a wider audience. So, we prepared A/B with Conversion Rate to purchase as primary metric and Click-Through Rate as secondary. We ran an A/B test for a new location page to test how it converts new users to make a reservation vs. old location pages.
      </p>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
        <ReactCompareSlider className="compare"
          itemOne={<ReactCompareSliderImage src={oldWeb} alt="Image one" />}
          itemTwo={<ReactCompareSliderImage src={newWeb} alt="Image two" />}
        />
      </AnimationOnScroll>
      <div className="web-screens">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/map.png"
            alt="Map"
          />
        </AnimationOnScroll>
        <AnimationOnScroll className="middle-screen" animateIn="animate__slideInUp" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/checkout.png"
            alt="Checkout"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/duration.png"
            alt="Duration"
          />
        </AnimationOnScroll>
      </div>
      <Wrapper>
        <p>
          We used Sketch until I started working on mobile apps. The rebrand felt like a good time to give Figma a spin, especially since Figma had deeper integrations with the front-end. 
        </p>
        <p>
          While we were in the middle of refreshing the branding and design, the engineering team had been working on changing the tech-stack.
          <br />
          The site was riddled with tech debt from a slow move from Bootstrap and page-specific CSS to more modular CSS. In short, the platform was extremely difficult to work on and was connected to other apps. The rebranding presented the engineering team with the perfect opportunity to start over completely. We decided to launch the rebranding on a brand new tech-stack and extract from the core app. The NextJS-based web-app with the Atomic Design System helped us to better connect new integrations.
        </p>
      </Wrapper>
      <div className="web-screens">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/no_results.png"
            alt="No Search Results"
          />
        </AnimationOnScroll>
        <AnimationOnScroll className="middle-screen" animateIn="animate__slideInUp" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/branded_v2.png"
            alt="Checkout"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/branded.png"
            alt="Duration"
          />
        </AnimationOnScroll>
      </div>
      <Wrapper id="results">
        <strong className="sub-title">Reflections</strong>
        <h3>Positive results and much more to do</h3>
        <p>
          The redesign of the checkout flow in the web app didn't have a negative short-term impact and had a positive long-term impact after launch.
          Redesign the web-app unlocked for us more opportunities for collaborations and integrations.
          <br />
          The Design System has had a big impact on how designers and engineers build features at Premium Parking. I can say this with confidence two years later.
        </p>
        <ul>
          <li>It’s easier for designers and engineers to collaborate, since they now have a common language (and design language) to do so.</li>
          <li>Engineers can make a lot of progress when building a feature without as much design oversight.</li>
          <li>It's sped up feature development time by an order of magnitude, since it almost defeats new code writing</li>
          <li>Designers now have the space to focus on customer problems, product strategy, and user flows.</li>
        </ul>
      </Wrapper>
      <Projects project="rebrand" />
    </AnimationOnScroll>
  <Footer />
</Layout>
  )
}

const OldBrand = styled.div`
  background: rgb(215, 215, 235, .1);
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 8px;

  @media screen and (prefers-color-scheme: light) {
    background: var(--color-bg);
  }

  .light & {
    background: var(--color-bg);
  }

  .dark & {
    background: rgb(215, 215, 235, .1);
  }
`
const Head = styled.div`
  background: #DA232F;
  text-align: center;
  padding: 50px 30px 0;
  position: relative;
  margin-bottom: 80px;
  transition: background 500ms linear;
  overflow: hidden;

  .badge {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  &.hd-1 {
    background: #111;
  }

  &.hd-2 {
    background: #000;
  }

  &.hd-3 {
    background: #0E52B4;
  }

  &.hd-4 {
    background: #1e6aaa;
  }

  &.hd-5 {
    background: #002c51;
  }

  .spot {
    display: inline-block;
    position: relative;
  }

  .vehicle {
    position: absolute;
    left: 25px;
    top: 38px;
  }

  @media (max-width: 1024px) {
    text-align: left;

    .space {
      margin-bottom: -40px;
    }
  }

  @media (max-width: 800px) {
    .badge {
      width: 150px;
    }
  }

  @media (max-width: 730px) {
    padding-right: 150px;
    padding-left: 0;
    padding-top: 0;

    .animate__animated {
      display: flex;
    }
  }

  @media (max-width: 600px) {
    .space-first {
      display: none;
    }
  }

  @media (max-width: 380px) {
    height: 350px;

    .badge {
      bottom: 10px;
    }
  }
`

const Ds = styled.div`
  background: #F2F2F2;
  padding: 30px 0 50px 20px;
  overflow: hidden;

  svg {
    max-width: 100%;
    height: auto;
  }

  .d-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: end;
    margin-top: -50px;
    justify-content: space-between;
  }

  .fc {
    padding-top: 80px;
    max-width: 335px;
    margin-right: 20px;
  }

  .rc {
    margin-bottom: 35px;
    left: -70px;
    position: relative;
  }

  .sc {
    align-self: flex-start;
  }

  .cc {
    align-self: flex-start;
    padding-top: 40px;
    text-align: right;
  }

  .cc .animate__animated {
    margin-top: 50px;
  }

  .shift-r {
    right: -50px;
    position: relative;
  }

  @media (max-width: 1280px) {
    .shift-r {
      right: -10px;
    }
  }

  @media (max-width: 1024px) {
    .rc {
      left: -30px;
    }
  }

  @media (max-width: 960px) {
    .sc {
      padding-top: 30px;
    }
  }

  @media (max-width: 700px) {
    .d-row {
      flex-wrap: wrap;
    }

    .col {
      width: 48%;
    }

    .rc {
      left: 0;
    }
  }

  @media (max-width: 535px) {
    .col {
      width: 45%;
    }

    .cc .animate__animated {
      margin-top: 30px;
    }

    padding: 20px 0 0 10px;
  }
`

export default IndexPage
